<template>
  <section>
    <anes-mapa />
  </section>
</template>
<script>
import AnesMapa from "@/components/AnesMapa.vue";
export default {
  name: "Contacto",
  components: { AnesMapa },
};
</script>
