<template>
  <div class="py-12 mx-auto overflow-x-hidden font-slab contain-dom">
    <h2 class="text-4xl font-bold text-center text-gray-title">
      Comités de apoyo
    </h2>
    <div class="mt-12" id="electoral">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité Electoral
      </h2>
      <div class="lg:flex">
        <img
          class="mx-auto rounded-lg lg:w-1/2"
          height="auto"
          width="auto"
          src="@/assets/image/logo.jpg"
          alt="Logo de la ANES"
        />
        <div class="lg:w-1/2">
          <p class="w-11/12 mx-auto my-8 text-lg text-justify text-gray-title">
            Comité Electoral se conformará con cinco Miembros, cuya elección
            será en la primera Asamblea General Ordinaria del año de elecciones
            y operará de conformidad al procedimiento establecido en el
            Reglamento respectivo y con las siguientes atribuciones:
          </p>
          <ul
            class="
              w-11/12
              mx-auto
              text-justify
              list-decimal list-inside
              text-gray-title
            "
          >
            <li class="mb-5">
              <span
                >Elaborar y/o actualizar el reglamento para el proceso de
                elecciones.</span
              >
            </li>
            <li class="mb-5">
              <span>Planificar y Organizar el proceso de elecciones. </span>
            </li>
            <li class="mb-5">
              <span
                >Divulgar en coordinación con Junta Directiva el proceso de
                elecciones.
              </span>
            </li>
            <li class="mb-5">
              <span
                >Inscribir la planilla de candidatos presentadas por los
                miembros.</span
              >
            </li>
            <li class="mb-5">
              <span
                >Recibir el Dictamen del Comité de Ética y Legislación sobre los
                candidatos inscritos en planillas.</span
              >
            </li>
            <li class="mb-5">
              <span
                >Presidir las reuniones de consulta y entrevistas de candidatos
                a elección.</span
              >
            </li>
            <li class="mb-5">
              <span
                >Vigilar que los procesos de elección se desarrollen de
                conformidad con los presentes Estatutos</span
              >
            </li>
            <li class="mb-5">
              <span
                >Realizar la elección de junta Directiva en Asamblea General
                Extraordinaria.</span
              >
            </li>
            <li class="mb-5">
              <span
                >El escrutinio se realizará en Asamblea general Extraordinaria
                con la presencia de un miembro representante de cada planilla,
                un representante del comité electoral y un miembro del comité de
                Ética y Legislación.</span
              >
            </li>
            <li class="mb-5">
              <span
                >Dar a conocer los resultados de la elección en la misma
                Asamblea y realizar su juramentación por la Presidenta
                saliente.</span
              >
            </li>
            <li class="mb-5">
              <span
                >Presentar en Asamblea General el reglamento electoral para su
                debida aprobación.</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mt-12">
      <h2
        id="cientifico"
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité Científico
      </h2>
      <div class="flex flex-col-reverse mt-8 md:flex-row">
        <div class="md:w-1/2">
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité Científico tendrá las atribuciones Siguientes:
          </p>
          <ul
            class="
              mx-auto
              text-justify
              list-decimal list-inside
              lg:pr-14
              text-gray-title
              w-72
              md:w-auto md:mr-3
            "
          >
            <li class="mb-5">
              <span
                >Planificar, organizar, coordinar, ejecutar y evaluar Congresos,
                Jornadas, Conferencias y otros eventos científicos.
              </span>
            </li>

            <li class="mb-5">
              <span
                >Elaborar y presentar informe a Junta Directiva de las
                actividades desarrolladas.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Apoyar en la logística en los eventos científicos a
                Desarrollar.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Promocionar los eventos científicos en los diferentes sectores
                de Enfermería y en los departamentos con el apoyo de las
                filiales.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Divulgar los eventos científicos en los diferentes medios de
                comunicación y redes Sociales.
              </span>
            </li>
          </ul>
        </div>
        <div class="w-11/12 mx-auto mb-10 md:w-1/2 h-96">
          <VueSlickCarousel v-bind="settings">
            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image01.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image02.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image03.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image04.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image05.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image06.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image07.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image08.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image09.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image10.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image11.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-96"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-cientifico/Image12.webp"
              alt="Comite"
            />
          </VueSlickCarousel>
        </div>
      </div>
    </div>

    <div class="mt-16" id="etica">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité de Ética
      </h2>
      <div
        class="
          flex flex-col-reverse
          mt-8
          md:flex-row-reverse
          xl:h-96 xl:justify-evenly
        "
      >
        <div class="md:w-1/2">
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité Ética tendrá las atribuciones Siguientes:
          </p>
          <ul
            class="
              mx-auto
              text-justify
              list-decimal list-inside
              text-gray-title
              w-72
              md:w-auto
            "
          >
            <li class="mb-5">
              <span
                >Investigar las denuncias o quejas que se presenten a la Junta
                Directiva, por hechos contrarios a la ética profesional
              </span>
            </li>

            <li class="mb-5">
              <span
                >Fomentar los valores y principios éticos entre el gremio de
                Enfermería durante el cumplimiento de sus actividades
                profesionales.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Vigilar que los procesos de elección de la Junta Directiva se
                desarrollen de acuerdo a los Estatutos vigentes.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Participar con el comité de investigación científica en
                estudios sobre los problemas relacionados con el ejercicio de la
                Enfermería.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Proponer a Junta Directiva las recomendaciones a los problemas
                investigados con su respectivo informe.
              </span>
            </li>
            <li class="mb-5">
              <span
                >Participar en las elecciones de Junta Directiva de acuerdo a lo
                establecido en el reglamento electoral.
              </span>
            </li>
          </ul>
        </div>
        <img
          class="mx-auto md:w-1/2 md:mr-5 xl:w-96 xl:m-0"
          height="auto"
          width="auto"
          src="@/assets/image/logo.jpg"
          alt="Logo de la ANES"
        />
      </div>
    </div>

    <div id="higiene" class="mt-24">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité de Higiene y Seguridad Laboral
      </h2>
      <div class="flex flex-col-reverse mt-8 md:flex-row xl:h-96">
        <div class="md:w-1/2">
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité de Higiene y Seguridad Laboral tendrá las atribuciones
            Siguientes:
          </p>
          <ul
            class="
              mx-auto
              text-justify
              list-decimal list-inside
              lg:pr-14
              text-gray-title
              w-72
              md:w-auto
            "
          >
            <li class="mb-5">
              <span
                >Elaborar y presentar plan de trabajo a junta directiva.
              </span>
            </li>

            <li class="mb-5">
              <span
                >Presentar a Junta Directiva necesidades de investigación
                relacionadas a condiciones salariales y laborales de
                enfermería.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Participar en investigaciones relacionadas con las condiciones
                laborales y salariales de enfermería.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Socializar los resultados de las investigaciones realizadas a
                los niveles correspondientes.</span
              >
            </li>

            <li class="mb-5">
              <span>Impulsar la ejecución de la propuesta de mejora. </span>
            </li>

            <li class="mb-5">
              <span
                >Promover el cumplimiento del Art.149 de la OIT, referente a las
                prestaciones del personal de enfermería.
              </span>
            </li>

            <li class="mb-5">
              <span>Rendir informe de actividades realizadas. </span>
            </li>
          </ul>
        </div>
        <img
          class="mx-auto md:w-1/2 md:mr-5 xl:w-96"
          height="auto"
          width="auto"
          src="@/assets/image/logo.jpg"
          alt="Logo de la ANES"
        />
      </div>
    </div>

    <div class="mt-24" id="relaciones">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité de Relaciones Públicas
      </h2>
      <div
        class="
          flex flex-col-reverse
          justify-between
          mt-8
          md:flex-row-reverse
          lg:h-etica
          xl:h-lider
        "
      >
        <div class="md:w-1/2">
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité de Relaciones públicas tendrá las atribuciones Siguientes:
          </p>
          <ul class="text-justify list-decimal list-inside text-gray-title">
            <li class="mb-5">
              <span
                >Apoyar la Logística de atención de representantes Nacionales e
                Internacionales.
              </span>
            </li>

            <li class="mb-5">
              <span
                >Elaborar y publicar la revista anual de logros de la
                Asociación.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Debe coordinar con el comité de revista para divulgar las
                actividades nacionales e internacionales relevantes realizadas y
                promocionar la Organización con todos los sectores de
                Enfermería, redes sociales, medios de comunicación, así como la
                Inscripción de nuevos socios.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Elaborar en forma digital la propuesta del suplemento del día
                de la enfermera.</span
              >
            </li>

            <li>
              <span
                >Elaborar, ejecutar y presentar Plan de Trabajo e informes de
                actividades a Junta Directiva Nacional.
              </span>
            </li>
          </ul>
        </div>
        <img
          class="mx-auto md:w-1/2 md:mr-5 xl:w-mini md:mx-0"
          height="auto"
          width="auto"
          src="@/assets/image/logo.jpg"
          alt="Logo de la ANES"
        />
      </div>
    </div>

    <div class="mt-20" id="mejoramiento">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité Pro-Casa
      </h2>
      <div class="flex flex-col-reverse mt-8 md:flex-row lg:h-72">
        <div class="md:w-1/2">
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité Pro-casa tendrá las atribuciones Siguientes:
          </p>
          <ul class="text-justify list-decimal list-inside text-gray-title">
            <li class="mb-5">
              <span
                >Elaborar y presentar plan de trabajo a Junta Directiva
                Nacional.
              </span>
            </li>

            <li class="mb-5">
              <span
                >Presentar proyectos relacionados a la mejora de la casa de la
                Enfermera
              </span>
            </li>

            <li class="mb-5">
              <span
                >Desarrollar actividades que contribuyan al mejoramiento, el
                confort y ambientación de la casa de la Enfermera.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Presentar informe a Junta Directiva y en asamblea de
                actividades realizadas.</span
              >
            </li>
          </ul>
        </div>

        <img
          class="mx-auto md:w-1/2 md:mr-5 lg:w-72"
          height="auto"
          width="auto"
          src="@/assets/image/logo.jpg"
          alt="Logo de la ANES"
        />
      </div>
    </div>

    <div class="mt-16" id="estimulos">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité de Estímulos
      </h2>
      <div
        class="
          flex flex-col-reverse
          mt-8
          md:flex-row-reverse
          xl:justify-evenly xl:h-96
        "
      >
        <div class="md:w-1/2">
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité de Estímulos tendrá las atribuciones Siguientes:
          </p>
          <ul
            class="
              mx-auto
              text-justify
              list-decimal list-inside
              text-gray-title
              w-72
              md:w-auto
            "
          >
            <li class="mb-5">
              <span>Elaborar plan de trabajo. </span>
            </li>

            <li class="mb-5">
              <span
                >Actualizar los requisitos del proceso de selección del personal
                de enfermería que participara al máximo Galardón, medalla Sor
                María Lang y Medalla ANES.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Revisión y análisis de Curriculum, de las participantes de para
                las medallas.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Fomentar la participación de las socias y socios al máximo
                Galardón a través de los diferentes medios.
              </span>
            </li>

            <li class="mb-5">
              <span
                >Presentar informe del proceso de Selección a Junta Directiva.
              </span>
            </li>

            <li class="mb-5">
              <span
                >Informar en Asamblea General la nómina de los galardonados y su
                hoja de vida.
              </span>
            </li>

            <li class="mb-5">
              <span
                >Elaborar y presentar informe de actividades realizadas.
              </span>
            </li>
          </ul>
        </div>
        <img
          class="mx-auto md:w-1/2 md:mr-5 xl:w-96 xl:m-0"
          height="auto"
          width="auto"
          src="@/assets/image/logo.jpg"
          alt="Logo de la ANES"
        />
      </div>
    </div>

    <div class="mt-24" id="dia">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité Pro-Día
      </h2>
      <div
        class="flex flex-col-reverse mt-8 md:flex-row xl:justify-evenly xl:h-80"
      >
        <div class="md:w-1/2">
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité Pro-Día tendrá las atribuciones Siguientes:
          </p>
          <ul
            class="
              mx-auto
              text-justify
              list-decimal list-inside
              lg:pr-14
              text-gray-title
              w-72
              md:w-auto
            "
          >
            <li class="mb-5">
              <span
                >Apoyar a la Junta Directiva en la Planificación, organización y
                desarrollo de las actividades relacionadas con la celebración
                del Día de la Enfermera a nivel nacional.
              </span>
            </li>

            <li class="mb-5">
              <span
                >Revisión de Expediente de socios activos que cumplen 25 años,
                50 años de ser socios y Jubiladas del año anterior.
              </span>
            </li>

            <li class="mb-5">
              <span
                >Presentar a Junta Directiva, asamblea y Filiales el informe de
                Nómina de participantes.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Presentar a Junta Directiva el Informe de las actividades
                realizadas.</span
              >
            </li>

            <li class="mb-5">
              <span
                >Promover la mejor atención de las afiliadas el día de los actos
                oficiales.</span
              >
            </li>
          </ul>
        </div>
        <div class="w-11/12 mx-auto mb-10 md:w-1/2 h-96">
          <VueSlickCarousel v-bind="settings">
            <img
              class="rounded-lg h-80"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-pro-familia/Image01.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-80"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-pro-familia/Image02.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-80"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-pro-familia/Image03.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-80"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-pro-familia/Image04.webp"
              alt="Comite"
            />

            <img
              class="rounded-lg h-80"
              height="auto"
              width="auto"
              src="@/assets/image-webp/comite-pro-familia/Image05.webp"
              alt="Copro-familia"
            />
          </VueSlickCarousel>
        </div>
      </div>
    </div>

    <div class="mt-24" id="investigar">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité de Investigación
      </h2>
      <div
        class="
          flex flex-col-reverse
          mt-8
          md:justify-between md:flex-row-reverse
          lg:h-80
        "
      >
        <ul
          class="
            mx-auto
            text-justify
            list-decimal list-inside
            text-gray-title
            w-72
            md:w-1/2
          "
        >
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité de Investigación tendrá las atribuciones Siguientes:
          </p>

          <li class="mb-5">
            <span>Elaborar plan de trabajo. </span>
          </li>

          <li class="mb-5">
            <span
              >Elaborar diagnóstico para identificar líneas gremiales de
              investigación que le permitan la elaboración de proyectos.</span
            >
          </li>

          <li class="mb-5">
            <span>Planificar y ejecutar investigación.</span>
          </li>

          <li class="mb-5">
            <span>Identificar posibles fuentes de financiamiento. </span>
          </li>

          <li class="mb-5">
            <span>Socialización de los proyectos </span>
          </li>

          <li class="mb-5">
            <span>Elaborar y presentar informes. </span>
          </li>

          <li class="mb-5">
            <span>Elaborar artículos científicos para publicaciones </span>
          </li>
        </ul>
        <img
          class="mx-auto md:w-1/2 md:mr-5 lg:w-80"
          height="auto"
          width="auto"
          src="@/assets/image/logo.jpg"
          alt="Logo de la ANES"
        />
      </div>
    </div>

    <div class="mt-24" id="lider">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité de Liderazgo
      </h2>
      <div class="flex flex-col-reverse mt-8 md:flex-row lg:h-lider">
        <ul
          class="
            mx-auto
            text-justify
            list-decimal list-inside
            lg:pr-14
            text-gray-title
            w-72
            md:w-1/2
          "
        >
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité de Liderazgo tendrá las atribuciones Siguientes:
          </p>
          <li class="mb-5">
            <span
              >Elaborar propuestas de visibilización de la profesión en espacios
              de toma de decisiones y mantener la estructura de Enfermería.
            </span>
          </li>

          <li class="mb-5">
            <span>Promover el liderazgo gremial. </span>
          </li>

          <li class="mb-5">
            <span
              >Hacer incidencia en políticas públicas, leyes, convenios,
              tratados, que favorezcan el derecho a la salud de la población y
              sus asociados.
            </span>
          </li>

          <li class="mb-5">
            <span
              >Apoyar proyectos que fortalezcan el liderazgo de los
              asociados.</span
            >
          </li>

          <li class="mb-5">
            <span
              >Promover las condiciones de participación y compromiso en los
              asociados.</span
            >
          </li>
        </ul>

        <img
          class="mx-auto md:w-1/2 md:mr-5 lg:w-mini"
          height="auto"
          width="auto"
          src="@/assets/image/logo.jpg"
          alt="Logo de la ANES"
        />
      </div>
    </div>

    <div class="mt-16" id="diseño">
      <h2
        class="
          mx-auto
          mt-12
          mb-6
          text-3xl
          font-bold
          text-center
          md:w-96
          back-title-comite
          text-white-bone
        "
      >
        Comité Diseño y Edición de Revista
      </h2>
      <div
        class="
          flex flex-col-reverse
          justify-between
          mt-8
          md:flex-row-reverse
          lg:h-72
        "
      >
        <div class="md:w-1/2">
          <p class="mx-auto w-72 mb-7 md:w-auto md:mx-0">
            Comité Diseño y Edición de Revista tendrá las atribuciones
            Siguientes:
          </p>
          <ul
            class="
              mx-auto
              text-justify
              list-decimal list-inside
              md:mx-0
              lg:pr-14
              text-gray-title
              w-72
              md:w-auto
            "
          >
            <li class="mb-5">
              <span>Elaborar plan de trabajo. </span>
            </li>

            <li class="mb-5">
              <span>Elaborar revista. </span>
            </li>

            <li class="mb-5">
              <span
                >Gestionar el código internacional para la revista (ISBN).</span
              >
            </li>

            <li class="mb-5">
              <span>Identificar la información para elaborar la revista. </span>
            </li>

            <li class="mb-5">
              <span
                >Promover la elaboración de artículos de revisión para su
                respectiva publicación.
              </span>
            </li>

            <li class="mb-5">
              <span>Promover la revista digital. </span>
            </li>
          </ul>
        </div>
        <img
          class="mx-auto md:m-0 md:w-1/2 md:mr-5 lg:w-72"
          height="auto"
          width="auto"
          src="@/assets/image/logo.jpg"
          alt="Logo de la ANES"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Comite",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 6000,
        cssEase: "linear",
      },
    };
  },
};
</script>
